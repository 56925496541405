let option = {
  grid: {
    x: '6%',
    y: '20%',
    x2: 35,
    y2: '28%'
  },
  legend: {
    orient: 'horizontal',
    padding: [20, -10, 0, 0],
    right: '2%',
    itemWidth: 12,
    itemHeight: 2,
    data: [{ name: '缺陷数量', icon: 'rect' }]
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisTick: {
      show: false //去除x轴刻度线
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#888888'
      },
      rotate: -40 //倾斜角度
    },
    data: ['2021.1', '2020.2', '2020.2', '2020.2', '2020.2', '2020.2', '2021.1', '2020.2', '2020.2', '2020.2', '2020.2', '2020.2']
  },
  yAxis: {
    type: 'value',
    scale: true,
    splitLine: {
      //网格线
      lineStyle: {
        type: 'dashed' //设置网格线类型 dotted：虚线   solid:实线
      },
      show: true //隐藏或显示
    }
  },
  series: [
    {
      data: [0, 20, 40, 60, 80, 100, 20, 20, 34, 60, 70, 90],
      type: 'line',
      name: '缺陷数量',
      itemStyle: {
        normal: {
          color: '#31D2A8', //改变折线点的颜色
          lineStyle: {
            color: '#31D2A8' //改变折线颜色
          }
        }
      },
      areaStyle: {
        color: '#31D2A8', //背景色
        opacity: 0.3 //透明度
      }
    }
  ]
}
export default { option }
