<template>
  <div>
    <el-col :span="12">
      <div class="portrayal-container second " style="margin-right: 10px">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>工作量总计</span>
              <p>工作量总评：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="false ? 'color: #448bff' : 'color: #FF5050'">0.5分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="false ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
            </div>
            <div class="progress-info">
              <div>
                <span>开发测试工作量</span>
                <div style="display: flex; flex-direction: row; justify-content: space-between; margin: 5%">
                  <div v-for="item in workload">
                    <div style="text-align: center;position: relative">
                      <div class="completeness-progress-title">{{ item.name }}</div>
                      <div class="completeness-progress-value">{{ item.num }}</div>
                      <el-progress
                        type="circle"
                        class="progressWidth"
                        :percentage="item.speed"
                        color="#FFA759"
                        :stroke-width="10"
                        :show-text="false"
                      ></el-progress>
                    </div>
                    <div style="margin-top: 30px; text-align: center">123</div>
                    <div style="margin-top: 10px; text-align: center">高于平均值：1</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="portrayal-container second ">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>需求产出</span>
              <p>产出评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="true ? 'color: #448bff' : 'color: #FF5050'">0.8分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="true ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
            </div>
            <div class="data-select">
              <el-tabs v-model="dateStatus" @tab-click="handleClick">
                <el-tab-pane label="每月" name="1"></el-tab-pane>
                <el-tab-pane label="季度" name="2"></el-tab-pane>
                <el-tab-pane label="每年" name="3"></el-tab-pane>
              </el-tabs>
              <datePicker ref="datePicker"></datePicker>
            </div>
          </div>
          <div id="academicRecord" style="width: 100%; height: 240px; margin-top: 55px"></div>
        </div>
      </div>
    </el-col>
    <el-col :span="24">
      <div class="portrayal-container second">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>生产效率</span>
              <p>投入评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="true ? 'color: #448bff' : 'color: #FF5050'">0.8分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="true ? 'point-rise ' : 'point-drop'"></YTIcon>
            </div>
          </div>
        </div>
        <div style="display: flex;flex-direction: row">
          <div style="width: 49% ;height: 280px;padding-left: 20px">
            <div class="data-select">
              <el-tabs v-model="dateStatus" @tab-click="handleClick">
                <el-tab-pane label="每月" name="1"></el-tab-pane>
                <el-tab-pane label="季度" name="2"></el-tab-pane>
                <el-tab-pane label="每年" name="3"></el-tab-pane>
              </el-tabs>
              <datePicker ref="datePicker"></datePicker>
            </div>
            <div id="pileUpLineChart" style="width: 100%; height: 240px;margin-top: 55px"></div>
          </div>
          <div class="intervals"></div>
          <div style="width: 50%">
            <div id="pieChart" style="width: 100%; height: 280px"></div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="portrayal-container second " style="margin-right: 10px">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>生产效率</span>
              <p>投入评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="true ? 'color: #448bff' : 'color: #FF5050'">0.8分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="true ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
            </div>
            <div class="data-select">
              <el-tabs v-model="dateStatus" @tab-click="handleClick">
                <el-tab-pane label="每月" name="1"></el-tab-pane>
                <el-tab-pane label="季度" name="2"></el-tab-pane>
                <el-tab-pane label="每年" name="3"></el-tab-pane>
              </el-tabs>
              <datePicker ref="datePicker"></datePicker>
            </div>
          </div>
          <div id="pileUPCurveChart" style="width: 100%; height: 240px;margin-top: 55px"></div>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="portrayal-container second ">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>生产有效性</span>
              <p>投入评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="true ? 'color: #448bff' : 'color: #FF5050'">0.8分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="true ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
            </div>
            <div class="data-select">
              <el-tabs v-model="dateStatus" @tab-click="handleClick">
                <el-tab-pane label="每月" name="1"></el-tab-pane>
                <el-tab-pane label="季度" name="2"></el-tab-pane>
                <el-tab-pane label="每年" name="3"></el-tab-pane>
              </el-tabs>
              <datePicker ref="datePicker"></datePicker>
            </div>
          </div>
          <div id="columnChart" style="width: 100%; height: 260px;margin-top: 55px"></div>
        </div>
      </div>
    </el-col>
  </div>
</template>

<script>
import YTIcon from '../../../common/YTIcon'
import datePicker from './datePicker'
import baseAreaChart from '@components/manage/analysis/portrayals/charts/baseAreaChart'
import pileUPCurveChart from '@components/manage/analysis/portrayals/charts/pileUPCurveChart'
import columnChart from '@components/manage/analysis/portrayals/charts/columnChart'
import pileUpLineChart from '@components/manage/analysis/portrayals/charts/pileUpLineChart'
import pieChart from '@components/manage/analysis/portrayals/charts/pieChart'
export default {
  name: 'Workload',
  components: { YTIcon, datePicker, baseAreaChart, pileUPCurveChart, columnChart, pileUpLineChart, pieChart },
  data() {
    return {
      value1: null,
      statusColor: ['#7CAEEF', '#31D2A8', '#F16E57', '#FFBB00'],
      colors: ['#7CAEEF', '#7CAEEF', '#7CAEEF'],
      dateStatus: '1',
      workload: [
        { name: '产出', num: 4.5, speed: 89, rate: 4, higherAverage: true, fraction: 1 },
        { name: '投入', num: 6, speed: 45, rate: 1, higherAverage: false, fraction: 1.5 },
        { name: '生产效率', num: 8, speed: 89, rate: 2, higherAverage: true, fraction: 1 },
        { name: '生产力有效性', num: 2, speed: 81, rate: 5, higherAverage: false, fraction: 1.5 }
      ],
      academicChart: null,
      pileUPCurveChart: null,
      columnChart: null,
      pileUpLineChart: null,
      pieChart: null
    }
  },
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine() {
      this.academicChart = this.$echarts.init(document.getElementById('academicRecord'))
      this.academicChart.setOption(baseAreaChart.option)
      this.pileUPCurveChart = this.$echarts.init(document.getElementById('pileUPCurveChart'))
      this.pileUPCurveChart.setOption(pileUPCurveChart.option)
      this.columnChart = this.$echarts.init(document.getElementById('columnChart'))
      this.columnChart.setOption(columnChart.option)
      this.pileUpLineChart = this.$echarts.init(document.getElementById('pileUpLineChart'))
      this.pileUpLineChart.setOption(pileUpLineChart.option)
      this.pieChart = this.$echarts.init(document.getElementById('pieChart'))
      this.pieChart.setOption(pieChart.option)
      setTimeout(() => {
        window.onresize = () => {
          this.academicChart.resize()
          this.pileUPCurveChart.resize()
          this.columnChart.resize()
          this.pileUpLineChart.resize()
          this.pieChart.resize()
        }
      }, 200)
    },
    handleClick() {
      this.$nextTick(() => {
        this.$refs.datePicker.check(this.dateStatus)
      })
    }
  }
}
</script>

<style scoped lang="less">
.progressWidth {
  ::v-deep .el-progress-circle {
    width: calc(100vw * 130 / 2048) !important;
  }
}
.completeness-progress-title {
  position: absolute;
  top: 32%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.completeness-progress-value {
  position: absolute;
  top: 56%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #73787d;
}
.intervals {
  margin: 20px;
  width: 1px;
  height: 251px;
  background: #e2e4e8;
  opacity: 1;
}
.data-select {
  display: flex;
  margin-top: 21px;
  float: left;
  .search-select {
    width: 200px;
    height: 35px;
    margin-left: 10px;
    z-index: 999;
  }
}
.workload-content {
  padding: 10px 0 0 20px;
  p {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-right: 20px;
  }
  span {
    margin-top: 2px;
    font-size: 12px;
  }
  .workload {
    display: flex;
    flex-flow: nowrap;
    margin-bottom: 20px;
    .scores {
      font-size: 12px;
      color: #000000;
      font-weight: bold;
      margin-top: 2px;
    }
  }
  .workload-info {
    padding-left: 22px;
    padding-top: 48px;
    display: flex;
    flex-flow: nowrap;
    .workload-round {
      margin-right: 20px;
    }
  }
}
</style>
