<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="portrayal-container second">
          <div class="knowledge-content">
            <div class="knowledge-content-info">
              <div class="flex-content">
                <span>生产效率</span>
                <p>投入评价：</p>
                <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
                <div class="item">得分：</div>
                <div class="points">98分</div>
                <div class="points" :style="true ? 'color: #448bff' : 'color: #FF5050'">0.8分</div>
                <YTIcon :href="'#icon-jiantou'" slot="reference" :class="true ? 'point-rise ' : 'point-drop'"></YTIcon>
                <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
              </div>
            </div>
          </div>
          <div style="display: flex;flex-direction: row">
            <div style="width: 49% ;height: 280px;margin-top: 15px; padding-left: 20px">
              <span>总览情况</span>
              <div id="radarMapChart" style="height: 280px;margin-top: 15px; padding-left: 15px"></div>
            </div>
            <div class="intervals"></div>
            <div style="width: 50%">
              <div class="data-select">
                <el-tabs v-model="dateStatus" @tab-click="handleClick">
                  <el-tab-pane label="每月" name="1"></el-tab-pane>
                  <el-tab-pane label="季度" name="2"></el-tab-pane>
                  <el-tab-pane label="每年" name="3"></el-tab-pane>
                </el-tabs>
                <datePicker ref="datePicker"></datePicker>
              </div>
              <div id="baseAreaChart" style="width: 100%; height: 280px;margin-top: 55px"></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="portrayal-container second">
          <div class="knowledge-content">
            <div class="knowledge-content-info">
              <div class="flex-content">
                <span>工作量总计</span>
                <p>工作量总评：</p>
                <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
                <div class="item">得分：</div>
                <div class="points">98分</div>
                <div class="points" :style="false ? 'color: #448bff' : 'color: #FF5050'">0.5分</div>
                <YTIcon :href="'#icon-jiantou'" slot="reference" :class="false ? 'point-rise ' : 'point-drop'"></YTIcon>
              </div>
            </div>
          </div>
          <div style="display: flex;flex-direction: row">
            <div style="width: 49% ;height: 280px;margin-top: 15px; padding-left: 20px">
              <span>开发测试工作量</span>
              <div style="display: flex; flex-direction: row;justify-content: space-between; margin: 5%">
                <div v-for="item in workload">
                  <div style="text-align: center;position: relative">
                    <div class="completeness-progress-title">{{ item.name }}</div>
                    <div class="completeness-progress-value">{{ item.num }}</div>
                    <el-progress
                      type="circle"
                      :percentage="item.speed"
                      color="#FFA759"
                      :stroke-width="10"
                      class="progressWidth"
                      :show-text="false"
                    ></el-progress>
                  </div>
                  <div style="margin-top: 30px; text-align: center">123</div>
                  <div style="margin-top: 10px; text-align: center">高于平均值：1</div>
                </div>
              </div>
            </div>
            <div class="intervals"></div>
            <div style="width: 50%">
              <div class="data-select">
                <el-tabs v-model="dateStatus" @tab-click="handleClick">
                  <el-tab-pane label="每月" name="1"></el-tab-pane>
                  <el-tab-pane label="季度" name="2"></el-tab-pane>
                  <el-tab-pane label="每年" name="3"></el-tab-pane>
                </el-tabs>
                <datePicker ref="datePicker"></datePicker>
              </div>
              <div id="pileUpLineChart" style="width: 100%; height: 240px;margin-top: 55px"></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="portrayal-container second">
          <div class="knowledge-content">
            <div class="knowledge-content-info">
              <div class="flex-content">
                <span>工作质量</span>
                <p>工作质量评价：</p>
                <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
                <div class="item">得分：</div>
                <div class="points">98分</div>
                <div class="points" :style="false ? 'color: #448bff' : 'color: #FF5050'">0.5分</div>
                <YTIcon :href="'#icon-jiantou'" slot="reference" :class="false ? 'point-rise ' : 'point-drop'"></YTIcon>
                <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
              </div>
            </div>
          </div>
          <div style="display: flex;flex-direction: row">
            <div style="width: 49% ;height: 280px;margin-top: 15px; padding-left: 20px">
              <div style="display: flex; flex-direction: row">
                <div class="progress-content" style="width: 49%">
                  <span>完成度</span>
                  <div style="display: flex">
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="衡量在指定日期实际完成的任务数和计划完成的任务数之间的关系"
                        placement="top-start"
                      >
                        <p>计划任务完成度：67%</p>
                      </el-tooltip>
                      <el-progress :percentage="50" class="progress" :show-text="false"></el-progress>
                      <div class="rate">
                        <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors" style="margin-left: 8px"> </el-rate>
                      </div>
                      <el-tooltip class="item" effect="dark" content="衡量实际完成时间和计划完成时间之间的关系" placement="top-start">
                        <p>按期完成度：78%</p>
                      </el-tooltip>
                      <el-progress :percentage="50" class="progress" color="#31D2A8" :show-text="false"></el-progress>
                      <div class="rate">
                        <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="rate" style="margin-left: 8px"> </el-rate>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="interval"></div>
                <div class="progress-chart" style="width: 49%">
                  <span>缺陷</span>
                  <div id="pieChart" style="width: 100%; height: 240px"></div>
                </div>
              </div>
            </div>
            <div class="intervals"></div>
            <div style="width: 50%">
              <div class="data-select">
                <el-tabs v-model="dateStatus" @tab-click="handleClick">
                  <el-tab-pane label="每月" name="1"></el-tab-pane>
                  <el-tab-pane label="季度" name="2"></el-tab-pane>
                  <el-tab-pane label="每年" name="3"></el-tab-pane>
                </el-tabs>
                <datePicker ref="datePicker"></datePicker>
              </div>
              <div id="pileUPCurveChart" style="width: 100%; height: 240px;margin-top: 55px"></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="portrayal-container second " style="margin-right: 10px">
          <div class="knowledge-content">
            <div class="knowledge-content-info">
              <div class="flex-content">
                <span>知识水平</span>
                <p style="margin-left: 20px">考试名称：软件工程</p>
                <p style="margin-left: 60px">考试时间：2021.3.6</p>
                <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
              </div>
            </div>
            <div id="knowledgeRadarChart" style="width: 100%; height: 280px;margin-top: 15px"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="portrayal-container second "></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import datePicker from './datePicker'
import radarMapChart from '../portrayals/charts/radarMap'
import pieChart from '@components/manage/analysis/portrayals/charts/pieChart'
import knowledgeRadarChart from '@components/manage/analysis/portrayals/charts/knowledgeRadar'
import YTIcon from '../../../common/YTIcon'
import pileUpLineChart from '@components/manage/analysis/portrayals/charts/pileUpLineChart'
import pileUPCurveChart from '@components/manage/analysis/portrayals/charts/pileUPCurveChart'
import baseAreaChart from '@components/manage/analysis/portrayals/charts/baseAreaChart'
export default {
  name: 'populationPreview',
  components: { datePicker, radarMapChart, pieChart, knowledgeRadarChart, YTIcon, pileUpLineChart, baseAreaChart, pileUPCurveChart },
  data() {
    return {
      value1: 3,
      rateData: 3,
      score: 98,
      dateStatus: '1',
      colors: ['#7CAEEF', '#7CAEEF', '#7CAEEF'],
      rate: ['#31D2A8', '#31D2A8', '#31D2A8'],
      workload: [
        { name: '产出', num: 4.5, speed: 89, rate: 4, higherAverage: true, fraction: 1 },
        { name: '投入', num: 6, speed: 45, rate: 1, higherAverage: false, fraction: 1.5 },
        { name: '生产效率', num: 8, speed: 89, rate: 2, higherAverage: true, fraction: 1 },
        { name: '生产力有效性', num: 2, speed: 81, rate: 5, higherAverage: false, fraction: 1.5 }
      ],
      radarMap: null,
      circularGraph: null,
      knowledgeRadarMap: null,
      pileUpLineChart: null,
      baseAreaChart: null,
      pileUPCurveChart: null
    }
  },
  mounted() {
    this.getEcharts()
  },
  methods: {
    getEcharts() {
      this.radarMap = this.$echarts.init(document.getElementById('radarMapChart'))
      this.radarMap.setOption(radarMapChart.option)
      this.circularGraph = this.$echarts.init(document.getElementById('pieChart'))
      this.circularGraph.setOption(pieChart.option)
      this.knowledgeRadarMap = this.$echarts.init(document.getElementById('knowledgeRadarChart'))
      this.knowledgeRadarMap.setOption(knowledgeRadarChart.option)
      this.pileUpLineChart = this.$echarts.init(document.getElementById('pileUpLineChart'))
      this.pileUpLineChart.setOption(pileUpLineChart.option)
      this.baseAreaChart = this.$echarts.init(document.getElementById('baseAreaChart'))
      this.baseAreaChart.setOption(baseAreaChart.option)
      this.pileUPCurveChart = this.$echarts.init(document.getElementById('pileUPCurveChart'))
      this.pileUPCurveChart.setOption(pileUPCurveChart.option)
      setTimeout(() => {
        window.onresize = () => {
          this.radarMap.resize()
          this.circularGraph.resize()
          this.knowledgeRadarMap.resize()
          this.pileUpLineChart.resize()
          this.baseAreaChart.resize()
          this.pileUPCurveChart.resize()
        }
      }, 200)
    },
    handleClick() {
      this.$nextTick(() => {
        this.$refs.datePicker.check(this.dateStatus)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.progressWidth {
  ::v-deep .el-progress-circle {
    width: calc(100vw * 130 / 2048) !important;
  }
}
.data-select {
  display: flex;
  margin-top: 21px;
  float: left;
  .search-select {
    width: 200px;
    height: 35px;
    margin-left: 10px;
    z-index: 999;
  }
}
.progressStyle {
  margin: 36px 40px 40px 40px;
}
.interval {
  margin: 120px 50px;
  width: 1px;
  height: 90px;
  background: #e2e4e8;
  opacity: 1;
}
.intervals {
  margin: 20px;
  width: 1px;
  height: 251px;
  background: #e2e4e8;
  opacity: 1;
}
.progress-chart {
  width: 400px;
}
.rateStyle {
  margin-top: 12px;
  width: 130px;
  height: 20px;
  padding: 2px 5px;
  background: #f0f2f5;
  opacity: 1;
  border-radius: 10px;
}
.completeness-progress-title {
  position: absolute;
  top: 32%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.completeness-progress-value {
  position: absolute;
  top: 56%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #73787d;
}
.progress-content {
  .progress {
    width: 200px;
    margin-left: 21px;
  }
  .rate {
    margin-left: 21px;
    margin-top: 12px;
    width: 130px;
    height: 16px;
    align-items: center;
    background: #f0f2f5;
    border-radius: 10px;
  }
  p {
    font-size: 12px;
    margin: 50px 0 12px 21px;
  }
  .line {
    width: 1px;
    height: 90px;
    background: #e2e4e8;
    float: right;
  }
}
</style>
