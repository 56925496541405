<template>
  <div>
    <el-col :span="12">
      <div class="portrayal-container second " style="margin-right: 10px">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>工作质量</span>
              <p>工作质量评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="false ? 'color: #448bff' : 'color: #FF5050'">0.5分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="false ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
            </div>
            <div class="progress-info">
              <span>完成度</span>
              <div class="progress-content">
                <div style="display: flex">
                  <div>
                    <el-tooltip class="item" effect="dark" content="衡量在指定日期实际完成的任务数和计划完成的任务数之间的关系" placement="top-start">
                      <p>计划任务完成度：67%</p>
                    </el-tooltip>
                    <el-progress :percentage="50" class="progress"></el-progress>
                    <div class="rate">
                      <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors" style="margin-left: 8px"> </el-rate>
                    </div>
                    <p>按期完成度：78%</p>
                    <el-progress :percentage="50" class="progress" color="#31D2A8"></el-progress>
                    <div class="rate">
                      <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="rate" style="margin-left: 8px"> </el-rate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="portrayal-container second ">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>质量缺陷</span>
              <p>质量缺陷评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="true ? 'color: #448bff' : 'color: #FF5050'">0.8分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="true ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon class="icons" :href="'#icon-tishi'" slot="reference"></YTIcon>
            </div>
            <div class="data-select">
              <el-tabs v-model="dateStatus" @tab-click="handleClick">
                <el-tab-pane label="每月" name="1"></el-tab-pane>
                <el-tab-pane label="季度" name="2"></el-tab-pane>
                <el-tab-pane label="每年" name="3"></el-tab-pane>
              </el-tabs>
              <datePicker ref="datePicker"></datePicker>
            </div>
          </div>
          <div id="academicRecord" style="width: 100%; height: calc(100vh * 350/ 800);margin-top: 15px"></div>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="portrayal-container second " style="margin-right: 10px">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>计划任务完成度</span>
              <p>按期完成率评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="false ? 'color: #448bff' : 'color: #FF5050'">0.5分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="false ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon :href="'#icon-tishi'" slot="reference" class="icons"></YTIcon>
            </div>
            <div class="data-select">
              <el-tabs v-model="dateStatus" @tab-click="handleClick">
                <el-tab-pane label="每月" name="1"></el-tab-pane>
                <el-tab-pane label="季度" name="2"></el-tab-pane>
                <el-tab-pane label="每年" name="3"></el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div id="curveChart" style="width: 100%;height: calc(100vh * 350 / 800);margin-top: 15px"></div>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="portrayal-container second ">
        <div class="knowledge-content">
          <div class="knowledge-content-info">
            <div class="flex-content">
              <span>按期完成度</span>
              <p>任务完成精确度评价：</p>
              <el-rate v-model="value1" disabled-void-color="#D3D3D3" disabled :colors="colors"> </el-rate>
              <div class="item">得分：</div>
              <div class="points">98分</div>
              <div class="points" :style="true ? 'color: #448bff' : 'color: #FF5050'">0.7分</div>
              <YTIcon :href="'#icon-jiantou'" slot="reference" :class="true ? 'point-rise ' : 'point-drop'"></YTIcon>
              <YTIcon :href="'#icon-tishi'" slot="reference" class="icons"></YTIcon>
            </div>
            <div class="data-select">
              <el-tabs v-model="dateStatus" @tab-click="handleClick">
                <el-tab-pane label="每月" name="1"></el-tab-pane>
                <el-tab-pane label="季度" name="2"></el-tab-pane>
                <el-tab-pane label="每年" name="3"></el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div id="lineChart" style="width: 100%;height: calc(100vh * 350 / 800);margin-top: 15px"></div>
        </div>
      </div>
    </el-col>
  </div>
</template>

<script>
import lineChart from './charts/lineChart'
import curveChart from './charts/curveChart'
import baseAreaChart from './charts/baseAreaChart'
import YTIcon from '../../../common/YTIcon'
import datePicker from './datePicker'
export default {
  name: 'Workmanship',
  components: {
    lineChart,
    curveChart,
    YTIcon,
    baseAreaChart,
    datePicker
  },
  data() {
    return {
      value1: 3,
      status: 1,
      dateStatus: '1',
      colors: ['#7CAEEF', '#7CAEEF', '#7CAEEF'],
      rate: ['#31D2A8', '#31D2A8', '#31D2A8'],
      workCurveChart: null,
      workLineChart: null,
      academicChart: null
    }
  },
  mounted() {
    this.drawLine()
  },
  methods: {
    drawLine() {
      this.workCurveChart = this.$echarts.init(document.getElementById('curveChart'))
      this.workCurveChart.setOption(curveChart.option)
      this.workLineChart = this.$echarts.init(document.getElementById('lineChart'))
      this.workLineChart.setOption(lineChart.option)
      this.academicChart = this.$echarts.init(document.getElementById('academicRecord'))
      this.academicChart.setOption(baseAreaChart.option)
      setTimeout(function() {
        window.onresize = function() {
          this.workLineChart.resize()
          this.workCurveChart.resize()
          this.academicChart.resize()
        }
      }, 200)
    },
    handleClick() {
      this.$nextTick(() => {
        this.$refs.datePicker.check(this.dateStatus)
      })
    }
  }
}
</script>

<style scoped lang="less"></style>
