<template>
  <div>
    <div v-if="status === '1'">
      <el-date-picker v-model="value1" type="month" placeholder="开始月份"> </el-date-picker>
      <el-date-picker v-model="value1" type="month" placeholder="结束月份"> </el-date-picker>
    </div>
    <Quarter v-else-if="status === '2'"></Quarter>
    <div style="display: flex" v-else>
      <el-date-picker v-model="value1" type="year" placeholder="开始年份"></el-date-picker>
      <el-date-picker v-model="value1" type="year" placeholder="结束年份"></el-date-picker>
    </div>
  </div>
</template>

<script>
import Quarter from '../Quarter'
export default {
  name: 'datePicker',
  components: { Quarter },
  data() {
    return {
      value1: null,
      status: '1'
    }
  },
  mounted() {},
  methods: {
    check(data) {
      this.status = data
    }
  }
}
</script>

<style scoped></style>
