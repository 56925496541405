let option = {
  tooltip: {
    trigger: 'item'
  },
  legend: [
    {
      orient: 'vertical',
      x: 'right',
      y: 'top',
      icon: 'circle', //  这个字段控制形状  类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
      itemWidth: 6, // 设置宽度
      itemHeight: 6, // 设置高度
      data: ['个人水平', '平均水平']
    }
  ],
  polar: [
    {
      indicator: [{ text: '生产效率' }, { text: '有效性' }, { text: '完成度' }, { text: '缺陷移除' }, { text: '技能水平' }],
      axisLine: {
        show: false
      },
      splitArea: {
        show: false
      }
    }
  ],
  calculable: true,
  series: [
    {
      name: '总览情况',
      type: 'radar',
      areaStyle: {},
      data: [
        {
          value: [43, null, null, null, 50],
          name: '个人水平',
          areaStyle: { color: '#FACBC2' }
        },
        {
          value: [null, null, 28, 35, 50],
          name: '个人水平',
          areaStyle: { color: '#B4EFE1' }
        },
        {
          value: [43, 100, 28, null, null],
          name: '个人水平',
          areaStyle: { color: '#D0E2FA' }
        },
        {
          value: [50, null, null, null, 42],
          name: '平均水平',
          areaStyle: { color: '#FACBC2' }
        },
        {
          value: [null, null, 28, 31, 42],
          name: '平均水平',
          areaStyle: { color: '#B4EFE1' }
        },
        {
          value: [50, 74, 28, null, null],
          name: '平均水平',
          areaStyle: { color: '#D0E2FA' }
        }
      ]
    }
  ]
}

export default { option }
