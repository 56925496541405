let option = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    x: 'right',
    y: 'top',
    data: ['掌握率', '平均掌握率']
  },
  polar: [
    {
      indicator: [
        { text: '编程基础', max: 1, axisLabel: { show: true } },
        { text: '工程能力', max: 1 },
        { text: '专业基础', max: 1 },
        { text: '编程操作', max: 1 }
      ],
      axisLine: {
        show: false
      },
      splitArea: {
        show: false
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed'
        }
      }
    }
  ],
  calculable: true,
  series: [
    {
      name: '总览情况',
      type: 'radar',
      data: [
        {
          value: [0.4, 0.6, 0.5, 0.8],
          name: '掌握率'
        },
        {
          value: [0.9, 0.7, 0.3, 0.5],
          name: '平均掌握率'
        }
      ]
    }
  ]
}

export default { option }
