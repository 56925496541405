let option = {
  grid: {
    x: '7%',
    y: '20%',
    x2: 35,
    y2: '28%'
  },
  legend: {
    orient: 'horizontal',
    padding: [20, -10, 0, 0],
    right: '2%',
    itemWidth: 12,
    itemHeight: 2,
    data: [{ name: '按期完成率', icon: 'rect' }]
  },
  xAxis: {
    type: 'category',
    data: ['2021.1', '2020.2', '2020.2', '2020.2', '2020.2', '2020.2', '2021.1', '2020.2', '2020.2', '2020.2', '2020.2', '2020.2'],
    axisTick: {
      show: false //去除x轴刻度线
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#888888'
      },
      rotate: -40 //倾斜角度
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      //网格线
      lineStyle: {
        type: 'dashed' //设置网格线类型 dotted：虚线   solid:实线
      },
      show: true //隐藏或显示
    }
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      name: '按期完成率',
      smooth: true,
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        normal: {
          color: '#FFC117', //改变折线点的颜色
          lineStyle: {
            color: '#FFC117' //改变折线颜色
          }
        }
      }
    }
  ]
}
export default { option }
