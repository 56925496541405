let option = {
  legend: {
    orient: 'horizontal',
    padding: [20, 30, 0, 0],
    right: '2%',
    itemWidth: 12,
    itemHeight: 2,
    data: [
      { name: '需求任务', icon: 'rect' },
      { name: '测试任务', icon: 'rect' },
      { name: '开发任务', icon: 'rect' }
    ]
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisTick: {
      show: false //去除x轴刻度线
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#888888'
      },
      rotate: -40 //倾斜角度
    },
    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
  },
  yAxis: {
    type: 'value',
    splitLine: {
      //网格线
      lineStyle: {
        type: 'dashed' //设置网格线类型 dotted：虚线   solid:实线
      },
      show: true //隐藏或显示
    }
  },
  series: [
    {
      name: '需求任务',
      type: 'line',
      stack: '总量',
      symbol: 'circle',
      symbolSize: 8,
      data: [120, 132, 101, 134, 90, 230, 210],
      itemStyle: {
        normal: {
          color: '#7CAEEF', //改变折线点的颜色
          lineStyle: {
            color: '#7CAEEF' //改变折线颜色
          }
        }
      }
    },
    {
      name: '测试任务',
      type: 'line',
      stack: '总量',
      data: [220, 182, 191, 234, 290, 330, 310],
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        normal: {
          color: '#31D2A8', //改变折线点的颜色
          lineStyle: {
            color: '#31D2A8' //改变折线颜色
          }
        }
      }
    },
    {
      name: '开发任务',
      type: 'line',
      stack: '总量',
      symbol: 'circle',
      symbolSize: 8,
      data: [150, 232, 201, 154, 190, 330, 410],
      itemStyle: {
        normal: {
          color: '#F16E57', //改变折线点的颜色
          lineStyle: {
            color: '#F16E57' //改变折线颜色
          }
        }
      }
    }
  ]
}
export default { option }
