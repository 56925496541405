<template>
  <div class="yt-main">
    <div class="yt-header">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>人才画像</el-breadcrumb-item>
        <el-breadcrumb-item>人员查看</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <el-row>
        <el-col :span="12">
          <div class="portrayal-container first " style="margin-right: 10px">
            <div class="title">
              <p>基本信息</p>
            </div>
            <div class="content">
              <div class="content-top">
                <img :src="require('@assets/portrayal/title.png')" style="width: 42px;height: 42px;background-color: #4acacb" alt="" />
                <div>
                  <p>姓名：<span>王雨晨</span></p>
                  <p>工号：<span>1245645</span></p>
                </div>
                <div>
                  <p>所属部门：<span>开发部门</span></p>
                  <p>所属岗位：<span>前端</span></p>
                </div>
              </div>
              <div class="content-middle">
                <div class="icon"></div>
                <span>升级攻略</span>
                <p>可以通过提升以下各项指标，提升个人水平哦~</p>
              </div>
              <div class="content-bottom">
                <p>1、工作量：由产出、投入、生产效率、生产力有效性四个指标构成</p>
                <p>2、工作质量：由计划任务完成度、按期完成度、缺陷移除三个主要指标构成</p>
                <p>3、知识水平：通过培训平台考试获取个人技能知识水平</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="portrayal-container first indicator">
            <div class="title">
              开发测试能力指标展示
            </div>
            <p>下方图标为4项具体指标，请点击每项具体查看</p>
            <div class="indicator-list">
              <div
                v-for="(item, index) in indicators"
                :key="index"
                class="item"
                :class="{ active: activeIndex === index }"
                @click="switchIndicator(index)"
              >
                <img :src="item.icon" alt="" />
                <div>{{ item.label }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <component ref="portrayalDetail" :is="indicators[activeIndex].component" :svgWidth="svgWidth" :statusEnum="statusEnum"></component>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import PopulationPreview from '@components/manage/analysis/portrayals/PopulationPreview'
import Workload from './portrayals/Workload'
import Workmanship from './portrayals/Workmanship'
// import Knowledgelevel from './portrayals/Knowledgelevel'
export default {
  name: 'PortrayalDetail',
  components: { YTIcon, PopulationPreview, Workload, Workmanship },
  data() {
    return {
      staffList: [],
      userId: '',
      //软件开发胜任力模型仪表盘
      competencyModelChart: null,
      activeIndex: 0,
      indicators: [
        {
          label: '总览 ',
          icon: require('@assets/portrayal/result.png'),
          name: '生产力',
          component: PopulationPreview
        },
        {
          label: '工作量',
          icon: require('@assets/portrayal/workload.png'),
          name: '质量',
          component: Workload
        },
        {
          label: '工作质量',
          icon: require('@assets/portrayal/workmanship.png'),
          component: Workmanship
        }
      ],
      svgWidth: 126,
      statusEnum: ['fall', 'no-change', 'rise'],
      uploadFile: null
    }
  },
  methods: {
    switchIndicator(index) {
      this.activeIndex = index
      this.$nextTick(() => {
        this.$refs.portrayalDetail.getPortrayalData(this.userId)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
}
.yt-header {
  align-items: center;
  border: none;
}
.yt-container {
  height: calc(100% - 48px);
  overflow-y: auto;
  padding: 10px 10px 20px;
}
::v-deep .portrayal-container {
  height: 100%;
  background-color: #ffffff;
  background-position: 100% 0;
  margin-bottom: 10px;
  &.first {
    height: 226px;
    font-size: @medium;
    &.indicator {
      p {
        margin-left: 22px;
        color: #888888;
        font-size: @small;
      }
    }
  }
  &.second {
    height: 337px;
    font-size: @medium;
    .knowledge-content {
      padding: 10px 20px 0 20px;
      .knowledge-content-info {
        .flex-content {
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            font-weight: bold;
          }
          p {
            margin-left: 20px;
            font-size: 12px;
          }
          .points {
            font-size: 12px;
            font-weight: bold;
            margin-left: 6px;
          }
          .point-rise {
            fill: #448bff;
            transform: rotate(180deg);
          }
          .point-drop {
            fill: #ff5050;
          }
          .item {
            margin-left: 60px;
            font-size: 12px;
          }
          .icons {
            margin-left: auto;
            width: 16px;
            height: 16px;
            fill: #999999;
          }
        }
        .progress-info {
          margin-top: 20px;
          span {
            font-size: 12px;
            font-weight: bold;
          }
          .progress-content {
            .progress {
              width: 200px;
              margin-left: 21px;
            }
            .rate {
              margin-left: 21px;
              margin-top: 12px;
              width: 130px;
              height: 16px;
              align-items: center;
              background: #f0f2f5;
              border-radius: 10px;
            }
            p {
              font-size: 12px;
              margin: 50px 0 12px 21px;
            }
            .line {
              width: 1px;
              height: 90px;
              background: #e2e4e8;
              float: right;
            }
          }
        }
        .data-select {
          display: flex;
          margin-top: 21px;
          float: left;
          .search-select {
            width: 200px;
            height: 35px;
            margin-left: 10px;
            z-index: 999;
          }
        }
      }
    }
  }
  &.three {
    height: calc(100vh * 281 / 800);
    min-height: 1022px;
    font-size: @medium;
    overflow-y: auto;
    .knowledge-content {
      padding: 10px 20px 0 20px;
      .knowledge-content-info {
        .flex-content {
          display: flex;
          align-items: center;
          p {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
      .konwledge-list {
        padding: 32px 63px 0 24px;
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          color: #000000;
        }
        .progress {
          width: calc(100vh * 728 / 800);
          margin-left: 20px;
        }
      }
    }
  }
  .content {
    padding-left: 44px;
    .content-top {
      display: flex;
      flex-flow: nowrap;
      p {
        padding: 0 60px 10px 20px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
      }
    }
    .content-middle {
      display: flex;
      flex-flow: nowrap;
      .icon {
        width: 24px;
        height: 24px;
        background: linear-gradient(135deg, #ff9e7e 0%, #ff1c00 100%);
        border-radius: 50%;
        opacity: 1;
      }
      span {
        font-size: 12px;
        font-weight: bold;
        color: #000000;
        padding-left: 6px;
        padding-right: 10px;
      }
      p {
        font-size: 10px;
        color: #888888;
      }
    }
    .content-bottom {
      margin-top: 10px;
      p {
        font-size: 10px;
        color: #000000;
        padding-bottom: 10px;
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    padding: 10px 0 10px 20px;
  }
  .evaluate {
    .flexStyle(flex, center, flex-start);
    flex-direction: column;
    margin-left: 28px;
  }
  .indicator-list {
    .flexStyle(flex, flex-start, center);
    margin-top: 22px;
    margin-left: 23px;
    .item {
      .flexStyle(flex, center, center);
      flex-direction: column;
      width: 100px;
      height: 120px;
      border-radius: 12px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #edf4ff;
        background-image: url('~@/assets/portrayal/corner.png');
        background-repeat: no-repeat;
        img {
          opacity: 1;
        }
        div {
          color: #448bff;
        }
        &:before {
          content: '';
          width: 24px;
          height: 6px;
          background: #448bff;
          border-radius: 3px;
          position: absolute;
          bottom: 10px;
        }
      }
      div {
        margin-top: 10px;
        font-size: @default;
        font-weight: 500;
        color: #94969e;
      }
      img {
        opacity: 0.2;
      }
    }
    .active {
      background-color: #edf4ff;
      background-image: url('~@/assets/portrayal/corner.png');
      background-repeat: no-repeat;
      img {
        opacity: 1;
      }
      div {
        color: #448bff;
      }
      &:before {
        content: '';
        width: 24px;
        height: 6px;
        background: #448bff;
        border-radius: 3px;
        position: absolute;
        bottom: 10px;
      }
    }
  }
  .rise {
    transform: rotate(180deg);
    fill: #448bff;
  }
  .no-change {
    display: none;
  }
  .fall {
    fill: #ff4242;
  }
  .arrows {
    width: 10px;
    height: 12px;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep.el-tabs__item {
  font-size: 12px;
}
::v-deep .el-tabs__item.is-active {
  color: #000000;
}
::v-deep.el-tabs__item:hover {
  color: #000000;
}
::v-deep .el-input__inner {
  border: 0;
}
</style>
